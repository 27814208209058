import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './core/auth.service';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { ReactiveFormsModule } from '@angular/forms';

import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppMaterialModule } from './app-material.module';

import { UserLoginComponent } from './users/user-login/user-login.component';
import { HomeComponent } from './home/home.component';
import { ListingComponent } from './listing/listing.component';
import { CalendardayComponent } from './calendarday/calendarday.component';
import { CalendardayFormComponent } from './calendarday-form/calendarday-form.component';
import { BookingFormComponent } from './booking-form/booking-form.component';

import { CalendardayService } from './calendarday.service';
import { ZerofillPipe } from './zerofill.pipe';
import { GetdatePipe } from './getdate.pipe';
import { BookingService } from './booking.service';
import { CartsComponent } from './carts/carts.component';
import { MycartsComponent } from './mycarts/mycarts.component';
import { RefreshComponent } from './refresh/refresh.component';
import { ConfirmcartComponent } from './confirmcart/confirmcart.component';
import { MapvaluePipe } from './mapvalue.pipe';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { CancelComponent } from './cancel/cancel.component';
import { NotificationDialogComponent } from './notification-dialog/notification-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    UserLoginComponent,
    HomeComponent,
    ListingComponent,
    CalendardayComponent,
    CalendardayFormComponent,
    BookingFormComponent,
    ZerofillPipe,
    MapvaluePipe,
    GetdatePipe,
    CartsComponent,
    MycartsComponent,
    RefreshComponent,
    ConfirmcartComponent,
    ThankyouComponent,
    CancelComponent,
    NotificationDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    BrowserAnimationsModule,
    AppMaterialModule,
    ReactiveFormsModule
  ],
  providers: [
    AuthService,
    CalendardayService,
    BookingService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
/*
  Removed - <button fxFlex mat-button color="primary"
  *ngIf="!authservice.isLoggedIn" (click)="authservice.loginWithGoogle()">Login with Google</button>
*/
