import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'zerofill'
})
export class ZerofillPipe implements PipeTransform {
  transform(value: number, length: number): string {
    return (String('0').repeat(length) + value).substr((length * -1), length);
  }
}
