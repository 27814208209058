import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Calendarday } from './models/calendarday.model';

@Injectable()
export class CalendardayService {
  constructor(private afs: AngularFirestore) { }

  list(): Observable<any[]> {
    return this.afs.collection<Calendarday>(
      'calendar',
      ref => ref.orderBy('ymd')
    ).valueChanges();
  }

  save(calendarday: Calendarday, mode: string): Promise<void> {
    if (mode === 'create') {
      const checkexistpromise = this.afs
        .collection<Calendarday>('calendar')
        .doc(calendarday.ymd.toString()).get().toPromise();
      return new Promise((resolve, reject) => {
        checkexistpromise.then((value) => {
          if (value.exists) {
            reject('Calendar day already exists');
          } else {
            this.afs
              .collection<Calendarday>('calendar')
              .doc(calendarday.ymd.toString())
              .set(calendarday, { merge: true }).then((value2) => {
                resolve(value2);
              }).catch((reason) => {
                reject(reason);
              });
          }
        });
      });
    }

    return this.afs
      .collection<Calendarday>('calendar')
      .doc(calendarday.ymd.toString())
      .set(calendarday, { merge: true });
  }

  delete(calendarday: Calendarday) {
    this.afs
      .collection<Calendarday>('calendar')
      .doc(calendarday.ymd.toString())
      .delete()
      .then((value) => {
      }).catch((reason) => { });
  }
}
