import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.scss']
})
export class ThankyouComponent implements OnInit {
  payref = '';
  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.payref = this.route.snapshot.paramMap.get('payref');
  }

}
