import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ViewChild, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl, Validators, FormGroupDirective } from '@angular/forms';
import { Bookingform } from '../models/bookingform.model';
import { BookingService } from '../booking.service';
import { Subject, Observable } from 'rxjs';
import { Listing } from '../models/listing.model';
import { Calendarday } from '../models/calendarday.model';
import { Booking } from '../models/booking.model';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';

@Component({
  selector: 'app-booking-form',
  templateUrl: './booking-form.component.html',
  styleUrls: ['./booking-form.component.scss']
})
export class BookingFormComponent implements OnInit, OnChanges {
  cartslots: Observable<Booking[]>;

  @Input() listingId: string;

  @Input() calendarday: Calendarday;

  @Input() slot1: {slotstate: string; mycart: string; };

  @Input() slot2: {slotstate: string; mycart: string; };

  @Input() slot3: {slotstate: string; mycart: string; };

  @Input() slot4: {slotstate: string; mycart: string; };

  @Input() mode: string;

  @Output() cancel = new EventEmitter<boolean>();

  @ViewChild(FormGroupDirective, { static: true }) bookingFormGroupDirective;

  extraOption = 'no';

  onChanges = new Subject<SimpleChanges>();

  bookingFormGroup: FormGroup = new FormGroup({

    fullname: new FormControl(null, [
      Validators.required
    ]),
    dedication: new FormControl(null, [
    ]),
    note: new FormControl(null, [
    ]),
    anonymous: new FormControl(null, [
    ]),
    extraOption: new FormControl(null, []),
    option: new FormControl(null, [])

  });

  bookingform: Bookingform = {};

  constructor(private bookingService: BookingService, private afs: AngularFirestore) {
    // this.bookingFormGroup.reset();
    // this.bookingFormGroupDirective.resetForm();

  }

  ngOnInit() {
    this.bookingform.listingId = this.listingId;
    this.bookingform.ymd = this.calendarday.ymd;
    this.bookingform.fullname = '';
    this.bookingform.dedication = '';
    this.bookingform.note = '';
    this.bookingform.anonymous = false;
    this.bookingFormGroup.controls.fullname.setValue(this.bookingform.fullname);
    this.bookingFormGroup.controls.dedication.setValue(this.bookingform.dedication);
    this.bookingFormGroup.controls.note.setValue(this.bookingform.note);
    this.bookingFormGroup.controls.option.setValue(0);

    const cartidPromise = this.bookingService.getcartid(this.listingId);
    cartidPromise.then(cartid => {
      this.cartslots = this.afs.collection<Booking>(
        'carts/' + cartid + '/slots',
        ref => ref.where('ymd', '==', this.calendarday.ymd)
      ).valueChanges();
      this.cartslots.subscribe((cartslots) => {
        let found = false;
        cartslots.forEach(cartslot => {
          if (cartslot.slot !== 5 && !found) {
            found = true;
            this.bookingFormGroup.controls.fullname.setValue(cartslot.fullname);
            this.bookingFormGroup.controls.dedication.setValue(cartslot.dedication);
            this.bookingFormGroup.controls.note.setValue(cartslot.note);
            this.bookingFormGroup.controls.anonymous.setValue(cartslot.anonymous);
          }
          if (cartslot.slot === 5) {
            this.bookingFormGroup.controls.option.setValue(cartslot.value);
            if (cartslot.value > 0) {
              this.extraOption = 'yes';
            }
          }
        });
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.onChanges.next(changes);
  }

  onSubmit() {
    this.bookingform.listingId = this.listingId;
    this.bookingform.ymd = this.calendarday.ymd;
    this.bookingform.fullname = this.bookingFormGroup.controls.fullname.value;
    this.bookingform.dedication = this.bookingFormGroup.controls.dedication.value;
    this.bookingform.note = this.bookingFormGroup.controls.note.value;
    this.bookingform.anonymous = this.bookingFormGroup.controls.anonymous.value;
    this.bookingform.option = this.bookingFormGroup.controls.option.value;
    this.bookingService.updateBooking(this.bookingform);
    this.bookingFormGroup.markAsPristine();
  }

  onCancel() {
    this.cancel.emit(true);
  }

  gettotal() {
    let total = 0;
    if (this.slot1.mycart === 'cart') {
      total += 1000;
    }
    if (this.slot2.mycart === 'cart') {
      total += 1000;
    }
    if (this.slot3.mycart === 'cart') {
      total += 1000;
    }
    if (this.slot4.mycart === 'cart') {
      total += 1000;
    }
    if (this.slot1.mycart === 'cart' && this.slot2.mycart === 'cart' && this.slot3.mycart === 'cart' && this.slot4.mycart === 'cart') {
      const option: number = +this.bookingFormGroup.controls.option.value;
      if (option !== null) {
        total += option;
      }
    } else {
      this.bookingFormGroup.controls.option.setValue(0);
    }
    return total;
  }
}
