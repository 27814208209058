import { Pipe, PipeTransform } from '@angular/core';
import { Calendarday } from './models/calendarday.model';

@Pipe({
  name: 'getdate'
})
export class GetdatePipe implements PipeTransform {

  transform(calendarday: Calendarday): Date {
    return new Date(calendarday.year, calendarday.month - 1, calendarday.day);
  }

}
