import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { User } from 'firebase';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authuser: User;
  roles: any[] = [];
  constructor(
    public afAuth: AngularFireAuth,
    public afs: AngularFirestore,
    public router: Router) {
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.authuser = user;
        localStorage.setItem(
          'user',
          JSON.stringify(this.authuser)
        );
        this.afs.collection(`users/${user.uid}/roles`).valueChanges({idField: 'id'}).subscribe(roles => {
          const rolesArray: any[] = [];
          roles.forEach(value => {
            rolesArray.push(value.id);
          });
          this.roles = rolesArray;
        });
      } else {
        localStorage.setItem('user', null);
      }
    });
  }

  async login(email: string, password: string) {
    try {
      await this.afAuth.auth.signInWithEmailAndPassword(email, password);
      this.router.navigate(['admin/list']);
    } catch (e) {
      alert('Error!' + e.message);
    }
  }

  async signup(email, password) {
    await this.afAuth.auth.createUserWithEmailAndPassword(email, password);
    this.router.navigate(['/']);
  }

  // Sign in with email/password
  async signin(email, password) {
    await this.afAuth.auth.signInWithEmailAndPassword(email, password);
    this.router.navigate(['/']);
  }

  async loginWithGoogle() {
    const provider = new auth.GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: 'select_account'
    });
    try {
      await this.afAuth.auth.signInWithPopup(provider);
    } catch (e) {
    }
    this.router.navigate(['/']);
  }

  async logout() {
    await this.afAuth.auth.signOut();
    localStorage.removeItem('user');
    this.router.navigate(['/']);
  }

  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return user !== null;
  }
}
