import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { BookingService } from '../booking.service';
import { Observable } from 'rxjs';
import { Booking } from '../models/booking.model';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { Cart } from '../models/cart.model';
import { AuthService } from '../core/auth.service';
import { Calendarday } from '../models/calendarday.model';
import { CalendardayComponent } from '../calendarday/calendarday.component';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'app-confirmcart',
  templateUrl: './confirmcart.component.html',
  styleUrls: ['./confirmcart.component.scss']
})
export class ConfirmcartComponent implements OnInit {
  cartid: string;
  cartslots: Observable<Booking[]>;
  cartslotDocs: Booking[] = [];
  cart: Observable<Cart>;
  total = 0;
  md5 = '';
  calendar: Observable<Calendarday[]>;
  constructor(private route: ActivatedRoute,
              private bookingService: BookingService,
              public authservice: AuthService,
              private afs: AngularFirestore) {

  }

  ngOnInit() {
    this.cartid = this.route.snapshot.paramMap.get('cartid');
    // this.bookingService.confirmCart(this.cartid);

    this.cart = this.afs.doc('carts/' + this.cartid).valueChanges();

    this.cartslots = this.afs.collection<Booking>(
        'carts/' + this.cartid + '/slots',
        ref => ref.orderBy('ymd', 'asc')
      ).valueChanges({idField: 'id'});

    this.cartslots.subscribe((cartslots) => {
      this.total = 0;
      let slotcount = 0;
      const newdocs = [];
      cartslots.forEach(async (cartslot) => {
          slotcount++;
          this.total += cartslot.value;
          const caldoc = (await this.afs.doc<Calendarday>('calendar/' + cartslot.ymd.toString()).get().toPromise()).data();
          cartslot.calname = caldoc.name;
          cartslot.caldate = new Date(caldoc.year, caldoc.month - 1, caldoc.day);
          newdocs.push(cartslot);
      });
      this.cartslotDocs = newdocs;

    });
  }

  confirmcart() {
    this.bookingService.confirmCart(this.cartid);

    /*
    <form action="https://sandbox.payfast.co.za/eng/process" method="POST">
    <input type="hidden" name="merchant_id" value="10000100">
    <input type="hidden" name="merchant_key" value="46f0cd694581a">
    <input type="hidden" name="return_url" value="https://www.yoursite.com/return">
    <input type="hidden" name="cancel_url" value="https://www.yoursite.com/cancel">
    <input type="hidden" name="notify_url" value="https://www.yoursite.com/notify">
    <input type="hidden" name="name_first" value="John">
    <input type="hidden" name="name_last" value="Doe">
    <input type="hidden" name="email_address" value="john@doe.com">
    <input type="hidden" name="cell_number" value="0823456789">
    <input type="hidden" name="m_payment_id" value="01AB">
    <input type="hidden" name="amount" value="100.00">
    <input type="hidden" name="item_name" value="Test Item">
    <input type="hidden" name="item_description" value="A test product">
    <input type="hidden" name="custom_int1" value="2">
    <input type="hidden" name="custom_str1" value="Extra order information">
    <input type="hidden" name="email_confirmation" value="1">
    <input type="hidden" name="confirmation_address" value="john@doe.com">
    */

  }

  onSubmit(paymentForm: NgForm, e: any) {
    e.target.submit();
  }

}
