import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserLoginComponent } from './users/user-login/user-login.component';
import { HomeComponent } from './home/home.component';
import { CalendardayComponent } from './calendarday/calendarday.component';
import { CartsComponent } from './carts/carts.component';
import { RefreshComponent } from './refresh/refresh.component';
import { ConfirmcartComponent } from './confirmcart/confirmcart.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { CancelComponent } from './cancel/cancel.component';


const routes: Routes = [
  { path: 'paymentreturn/:payref', component: ThankyouComponent },
  { path: 'paymentcancel/:payref', component: CancelComponent },
  { path: 'user/login', component: UserLoginComponent },
  { path: 'calendar/manage', component: CalendardayComponent },
  { path: 'carts/manage', component: CartsComponent },
  { path: 'confirmcart/:cartid', component: ConfirmcartComponent },
  { path: 'refresh', component: RefreshComponent },
  { path: '', component: HomeComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
