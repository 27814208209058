import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Cart } from '../models/cart.model';
import { Observable } from 'rxjs';
import { MatTableDataSource } from '@angular/material';
import { BookingService } from '../booking.service';

@Component({
  selector: 'app-carts',
  templateUrl: './carts.component.html',
  styleUrls: ['./carts.component.scss']
})
export class CartsComponent implements OnInit {
  public mode = 'none';
  public cart: Cart = { listingId: null, updated: null };
  public statusFilter: string;
  public carts$: Observable<any[]>;

  displayedColumns: string[] = ['listingId', 'slots', 'total', 'status', 'updated', 'actions'];
  dataSource = new MatTableDataSource<Cart>([]);

  constructor(private bookingService: BookingService, private changeDetectorRefs: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.carts$ = this.bookingService.carts(null);
    this.carts$
    .toPromise()
    .then((carts) => {
      this.dataSource = new MatTableDataSource<Cart>(carts);
    })
    .catch();
  }

  filterCarts(status: string) {
    this.carts$ = this.bookingService.carts(status);
  }

  markPaid(element: any) {
    this.bookingService.setPaid(element);
  }

  markCanceled(element: any) {
    this.bookingService.setCanceled(element);
  }

  getTotal(element) {
    let total = 0;
    if (element.slots !== undefined) {
      element.slots.forEach(slot => {
        total += (slot.value !== undefined ? slot.value : 0);
      });
    }
    return total;
  }

}
