import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Booking } from './models/booking.model';
import { Bookingform } from './models/bookingform.model';
import { Cart } from './models/cart.model';
import { firestore } from 'firebase/app';
import { map, take } from 'rxjs/operators';
import { Calendarday } from './models/calendarday.model';

@Injectable()
export class BookingService {

  constructor(private afs: AngularFirestore) {
    // cartitems$ = afs.collection
  }

  async getBooking(listingId, calendarday, slot) {
    const booking = await this.afs.doc<Booking>('listings/' + listingId + '/bookings/' + calendarday.ymd.toString() + '_' + slot)
      .get().toPromise();
    if (booking.exists) {
      return booking.data();
    }
    return undefined;
  }

  async updateBooking(bookingform: Bookingform) {
    const cartid = await this.getcartid(bookingform.listingId);
    const cartslots = await this.afs.collection<Booking>('carts/' + cartid + '/slots').get().toPromise();
    let lastcartslot: Booking = {};
    let slotcount = 0;
    cartslots.docs.forEach(async cartslotDoc => {
      const cartslot = cartslotDoc.data();
      if (cartslot.slot !== 5 && cartslot.ymd === bookingform.ymd) {
        slotcount++;
        cartslot.fullname = bookingform.fullname !== undefined ? bookingform.fullname : '';
        cartslot.dedication = bookingform.dedication !== undefined ? bookingform.dedication : '';
        cartslot.note = bookingform.note !== undefined ? bookingform.note : '';
        cartslot.anonymous = (bookingform.anonymous === true);
        cartslot.value = 1000;
        lastcartslot = cartslot;
        console.log(cartslot);
        await this.afs.doc<Booking>('carts/' + cartid + '/slots/' + cartslotDoc.id).set(
          {fullname: cartslot.fullname, dedication: cartslot.dedication,
            note: cartslot.note, anonymous: cartslot.anonymous, value: cartslot.value},
          {merge: true});
      }
    });
    if (slotcount === 4) {
      lastcartslot.slot = 5;
      lastcartslot.value = bookingform.option;
      await this.afs.doc<Booking>('carts/' + cartid + '/slots/' + bookingform.ymd.toString() + '_5').set(lastcartslot, {merge: true});
    } else {
      await this.afs.doc<Booking>('carts/' + cartid + '/slots/' + bookingform.ymd.toString() + '_5').delete();
    }
  }

  confirmCart(cartId: string) {
    this.afs.doc('carts/' + cartId).set({status: 'confirmed'}, {merge: true});
  }

  async getcartid(listingid: string): Promise<string> {
    let cartId = localStorage.getItem('cartid');

    return new Promise<string>(async (resolve, reject) => {
      if (cartId === null) {
        try {
          const docRef = await this.afs.collection('carts').add({
            listingId: listingid,
            updated: firestore.FieldValue.serverTimestamp(),
            status: 'cart'
          });
          cartId = docRef.id;
          localStorage.setItem('cartid', cartId);

        } catch (error)  {
          reject(error);
          return;
        }
        resolve(cartId);
        return;
      } else {
        try {
          const docExists = await this.afs.collection('carts').doc(cartId).get().toPromise();
          if (docExists.exists && docExists.data().status === 'cart') {
            await this.afs.collection('carts').doc(cartId).set({
              updated: firestore.FieldValue.serverTimestamp()
            }, { merge: true });
          } else {
            const docRef = await this.afs.collection('carts').add({
              listingId: listingid,
              updated: firestore.FieldValue.serverTimestamp(),
              status: 'cart'
            });
            cartId = docRef.id;
            localStorage.setItem('cartid', cartId);
          }
        } catch (error) {
          reject(error);
          return;
        }
        resolve(cartId);
        return;
      }
    });
  }

  list(listingIdVal: string): Observable<any[]> {
    return this.afs.collection<Booking>(
      'bookings',
      ref => ref.where('listingId', '==', listingIdVal).orderBy('ymd')
    ).valueChanges();
  }

  carts(status: string): Observable<any[]> {
    return this.afs.collection<Cart>(
      'carts',
      ref => {
        if (status !== null) {
          return ref.where('status', '==', status).orderBy('updated', 'desc');
        }
        return ref.orderBy('updated', 'desc');
      }
    ).valueChanges({ idField: 'id' }).pipe(map((values) => {
      values.forEach(async value => {
        const slots = await this.afs.collection<Cart>('carts/' + value.id + '/slots',
          ref => ref.orderBy('slotid', 'asc')
        ).get().toPromise();
        const slotsArray: any[] = [];
        let total = 0;
        slots.docs.forEach(async (slot) => {
          const slotdata = slot.data();
          total += slotdata.value;
          const caldoc = (await this.afs.doc<Calendarday>('calendar/' + slotdata.ymd.toString()).get().toPromise()).data();
          slotdata.calname = caldoc.name;
          slotdata.caldate = new Date(caldoc.year, caldoc.month - 1, caldoc.day);
          slotsArray.push(slotdata);
        });
        value.slots = slotsArray;
      });
      return values;
    }));
/*
    this.dataordersService.getDataorderdevices(params.get('RECID')).pipe(map(values => {
      for (const value of values) {
        if (value.hasOwnProperty('BARCODE')) {
          value.device = this.devicesService.getDevice(value.BARCODE);
        }
      }
      return values;
    }));
*/
  }

  async setPaid(element) {
    this.afs.collection('carts').doc(element.id).set({
      status: 'paid',
      updated: firestore.FieldValue.serverTimestamp()
    }, { merge: true });
  }

  async setCanceled(element) {
    this.afs.collection('carts').doc(element.id).set({
      status: 'canceled',
      updated: firestore.FieldValue.serverTimestamp()
    }, { merge: true });
  }

  async selectSlot(listingIdVal: string, ymdVal: number, slotVal: number) {
    let cartid: string = null;
    try {
      cartid = await this.getcartid(listingIdVal);
    } catch (error) {
      return error;
    }
    const bookingslot = {
      slotid: listingIdVal + '_' + ymdVal.toString() + '_' + slotVal.toString(),
      cartId: cartid,
      listingId: listingIdVal,
      ymd: ymdVal,
      slot: slotVal,
      value: 1000,
      fullname: '',
      dedication: '',
      note: '',
      anonymous: false,
      status: 'cart',
      updated: firestore.FieldValue.serverTimestamp()
    };

    try {
      const cartslot = await this.afs.doc('carts/' + cartid + '/slots/' + ymdVal.toString() + '_' + slotVal.toString()).get().toPromise();

      if (cartslot.exists) {
        await this.afs
          .collection('carts').doc(cartid).collection('slots')
          .doc(ymdVal.toString() + '_' + slotVal)
          .delete();
      } else {
        const cartslot1 = await this.afs.doc('carts/' + cartid + '/slots/' + ymdVal.toString() + '_1').get().toPromise();
        const cartslot2 = await this.afs.doc('carts/' + cartid + '/slots/' + ymdVal.toString() + '_2').get().toPromise();
        const cartslot3 = await this.afs.doc('carts/' + cartid + '/slots/' + ymdVal.toString() + '_3').get().toPromise();
        const cartslot4 = await this.afs.doc('carts/' + cartid + '/slots/' + ymdVal.toString() + '_4').get().toPromise();
        const cartslots = [cartslot1, cartslot2, cartslot3, cartslot4];
        let fullname = '';
        let dedication = '';
        let note = '';
        let anonymous = false;
        for (let i = 0; i < 4; i++) {
          if (cartslots[i].exists) {
            const cs = cartslots[i].data();
            fullname = cs.fullname !== undefined ? cs.fullname : '';
            dedication = cs.dedication !== undefined ? cs.dedication : '';
            note = cs.note !== undefined ? cs.note : '';
            anonymous = cs.anonymous !== undefined ? cs.anonymous : false;
          }
        }
        bookingslot.fullname = fullname;
        bookingslot.dedication = dedication;
        bookingslot.note = note;
        bookingslot.anonymous = anonymous;
        this.afs
          .collection('carts').doc(cartid).collection('slots')
          .doc(ymdVal.toString() + '_' + slotVal)
          .set(bookingslot, { merge: true });
      }
    } catch (error) {
      return;
    }
  }

  delete(booking: Booking) {
    this.afs
      .collection<Booking>('bookings')
      .doc(booking.ymd.toString())
      .delete()
      .then((value) => {

      }).catch((reason) => { });
  }
}
