import { Component, OnInit } from '@angular/core';
import { Calendarday } from '../models/calendarday.model';
import { Observable } from 'rxjs';
import { CalendardayService } from '../calendarday.service';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-calendarday',
  templateUrl: './calendarday.component.html',
  styleUrls: ['./calendarday.component.scss']
})
export class CalendardayComponent implements OnInit {
  public mode = 'none';
  public calendarday: Calendarday = { name: null, hebrew_name: null, hebrew_date: null, year: null, month: null, day: null, ymd: null };
  public calendardays$: Observable<any[]>;

  displayedColumns: string[] = ['name', 'hebrew_name', 'hebrew_date', 'date', 'actions'];
  dataSource = new MatTableDataSource<Calendarday>([]);

  constructor(private calendardayService: CalendardayService) {
  }

  ngOnInit() {
    this.calendardays$ = this.calendardayService.list();
    this.calendardays$
    .toPromise()
    .then((calendarday) => {
      this.dataSource = new MatTableDataSource<Calendarday>(calendarday);
    })
    .catch();
  }

  onAdd() {
    this.calendarday = { name: null, hebrew_name: null, hebrew_date: null, year: null, month: null, day: null, ymd: null };
    this.mode = 'create';
  }

  onUpdate(calendarday: Calendarday) {
    this.calendarday = calendarday;
    this.mode = 'update';
  }

  onDelete(calendarday: Calendarday) {
    if (this.calendarday.ymd === calendarday.ymd) {
      this.calendarday = { name: null, hebrew_name: null, hebrew_date: null, year: null, month: null, day: null, ymd: null };
      this.mode = 'none';
    }
    this.calendardayService.delete(calendarday);
  }

  onFormCancel() {
    this.mode = 'none';
  }
}
