import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BookingService } from '../booking.service';

@Component({
  selector: 'app-cancel',
  templateUrl: './cancel.component.html',
  styleUrls: ['./cancel.component.scss']
})
export class CancelComponent implements OnInit {

  payref = '';
  constructor(
    private route: ActivatedRoute,
    private bookingService: BookingService,
    private router: Router
  ) { }

  ngOnInit() {
    this.payref = this.route.snapshot.paramMap.get('payref');

  }

  async cancel() {
    const cartid = await this.bookingService.getcartid('VXQ20FkI1GfWPXN4x92u'); 
    await this.bookingService.setCanceled({id: cartid});
    this.router.navigate(['/']);
  }
}
