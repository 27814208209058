import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mapvalue'
})
export class MapvaluePipe implements PipeTransform {
  transform(value: Map<string, string>, key: string): string {
    return value.get(key);
  }
}
