import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ViewChild, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl, Validators, FormGroupDirective } from '@angular/forms';
import { Calendarday } from '../models/calendarday.model';
import { CalendardayService } from '../calendarday.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-calendarday-form',
  templateUrl: './calendarday-form.component.html',
  styleUrls: ['./calendarday-form.component.scss']
})
export class CalendardayFormComponent implements OnInit, OnChanges {

  @Input() calendarday: Calendarday;

  @Input() mode: string;

  @Output() cancel = new EventEmitter<boolean>();

  @ViewChild(FormGroupDirective, { static: true }) calendardayFormDirective;

  onChanges = new Subject<SimpleChanges>();

  calendardayForm: FormGroup = new FormGroup({
    name: new FormControl(null, [
      Validators.required
    ]),
    hebrew_name: new FormControl(null, [
    ]),
    hebrew_date: new FormControl(null, [
      Validators.required,
    ]),
    year: new FormControl(null, [
      Validators.required,
      Validators.pattern('^[1-9]{1}[0-9]*$'),
      Validators.max(9999),
      Validators.min(2019)
    ]),
    month: new FormControl(null, [
      Validators.required,
      Validators.pattern('^[1-9]{1}[0-9]*$'),
      Validators.max(12),
      Validators.min(1)
    ]),
    day: new FormControl(null, [
      Validators.required,
      Validators.pattern('^[1-9]{1}[0-9]*$'),
      Validators.max(31),
      Validators.min(1)
    ]),
  });

  constructor(private calendardayService: CalendardayService) { }

  ngOnInit() {
    if (this.mode === 'update') {
      this.calendardayForm.controls.name.setValue(this.calendarday.name);
      this.calendardayForm.controls.hebrew_name.setValue(this.calendarday.hebrew_name);
      this.calendardayForm.controls.hebrew_date.setValue(this.calendarday.hebrew_date);
      this.calendardayForm.controls.year.setValue(this.calendarday.year);
      this.calendardayForm.controls.month.setValue(this.calendarday.month);
      this.calendardayForm.controls.day.setValue(this.calendarday.day);
    }
    this.onChanges.subscribe((data: SimpleChanges) => {
      this.calendardayForm.reset();
      this.calendardayFormDirective.resetForm();
      if (this.mode === 'update') {
        this.calendardayForm.controls.name.setValue(this.calendarday.name);
        this.calendardayForm.controls.hebrew_name.setValue(this.calendarday.hebrew_name);
        this.calendardayForm.controls.hebrew_date.setValue(this.calendarday.hebrew_date);
        this.calendardayForm.controls.year.setValue(this.calendarday.year);
        this.calendardayForm.controls.month.setValue(this.calendarday.month);
        this.calendardayForm.controls.day.setValue(this.calendarday.day);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.onChanges.next(changes);
  }

  onSubmit() {
    this.calendarday.name = this.calendardayForm.controls.name.value;
    this.calendarday.hebrew_name = this.calendardayForm.controls.hebrew_name.value;
    this.calendarday.hebrew_date = this.calendardayForm.controls.hebrew_date.value;
    this.calendarday.year = +this.calendardayForm.controls.year.value;
    this.calendarday.month = +this.calendardayForm.controls.month.value;
    this.calendarday.day = +this.calendardayForm.controls.day.value;

    let monthstr = '';
    if (this.calendarday.month < 10) {
      monthstr = '0'.concat(this.calendarday.month.toString());
    } else {
      monthstr = this.calendarday.month.toString();
    }
    let daystr = '';
    if (this.calendarday.day < 10) {
      daystr = '0'.concat(this.calendarday.day.toString());
    } else {
      daystr = this.calendarday.day.toString();
    }
    this.calendarday.ymd = +(this.calendarday.year.toString() + monthstr + daystr);

    this.calendardayService.save(this.calendarday, this.mode).then((value) => {
      this.cancel.emit(true);
    }).catch((reason) => {
      this.calendardayForm.controls.code.setErrors({notUnique: true});
    });
  }

  onDelete(calendarday: Calendarday) {
    this.calendardayService.delete(calendarday);
  }

  onCancel() {
    this.cancel.emit(true);
  }
}
