import { Component } from '@angular/core';
import { AuthService } from './core/auth.service';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { ActivatedRoute, UrlSegment, Router, NavigationStart } from '@angular/router';
import { map } from 'rxjs/operators';

export interface Listing { name: string; }

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'kiddushsponsor';
  listingId = 'VXQ20FkI1GfWPXN4x92u';

  private listingDoc: AngularFirestoreDocument<Listing>;
  listing: Observable<Listing>;
  url = '';
  constructor(public authservice: AuthService, private afs: AngularFirestore, router: Router) {
    this.listingDoc = afs.doc<Listing>('listing/' + this.listingId);
    this.listing = this.listingDoc.valueChanges();
    router.events.subscribe( (e) => {
      if (e instanceof NavigationStart) {
        this.url = e.url;
      }
    });
  }
}
