import { Component, OnInit } from '@angular/core';
import { AuthService } from '../core/auth.service';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Calendarday } from '../models/calendarday.model';
import { Listing } from '../models/listing.model';
import { Booking } from '../models/booking.model';
import { BookingService } from '../booking.service';
import { Cart } from '../models/cart.model';
import { Router } from '@angular/router';
import { Slotstate } from '../models/slotstate.model';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  listingId = 'VXQ20FkI1GfWPXN4x92u';
  private listingDoc: AngularFirestoreDocument<Listing>;
  listing: Observable<Listing>;

  private calendarCollection: AngularFirestoreCollection<Calendarday>;
  calendar: Observable<Calendarday[]>;

  private slotstateCollection: AngularFirestoreCollection<Slotstate>;
  slotstates: Observable<Slotstate[]>;
  private slotstateMap: Map<string, string> = new Map();

  private cartslotsCollection: AngularFirestoreCollection<Booking>;
  cartslots: Observable<Booking[]>;
  private cartslotMap: Map<string, string> = new Map();
  loadingMap: Map<string, string> = new Map();
  cartslotDocs: Booking[] = [];

  bookingsMap: Map<string, Booking> = new Map();
  cart: Observable<Cart>;
  today = new Date();
  ymdtoday: number = (this.today.getFullYear() * 10000) + ((this.today.getMonth() + 1) * 100) + this.today.getDate();

  constructor(public authservice: AuthService,
              private afs: AngularFirestore,
              private bookingService: BookingService,
              private router: Router,
              private snackBar: MatSnackBar) {
    this.listingDoc = afs.doc<Listing>('listings/' + this.listingId);
    this.listing = this.listingDoc.valueChanges();
    this.calendarCollection = afs.collection<Calendarday>('calendar', ref => ref.where('ymd', '>', this.ymdtoday).orderBy('ymd', 'asc'));
    this.calendar = this.calendarCollection.valueChanges({idField: 'id'});

    bookingService.getcartid(this.listingId).then((cartid) => {
      this.cart = afs.doc('carts/' + cartid).valueChanges();
      this.cart.subscribe((cart) => {
        if (cart === undefined || (cart.status !== 'cart' && cart.status !== undefined)) {
          bookingService.getcartid(this.listingId).then((cartid2) => {
            window.location.reload();
          });
        } else {
          this.cartslotsCollection = afs.collection<Booking>('carts/' + cartid + '/slots', ref =>
            ref.where('listingId', '==', this.listingId).where('ymd', '>', this.ymdtoday).orderBy('ymd', 'asc'));
          this.cartslots = this.cartslotsCollection.valueChanges({idField: 'id'});
          this.cartslots.subscribe((cartslots) => {
            const newMap: Map<string, string> = new Map();
            this.cartslotDocs = cartslots;
            cartslots.forEach((cartslot) => {
              newMap.set(this.listingId + '_' + cartslot.ymd.toString() + '_' + cartslot.slot.toString(), cartslot.status);
            });
            this.cartslotMap = newMap;
          });
        }
      });
    }).catch((error) => {
      alert(error);
    });

    this.slotstateCollection = afs.collection<Slotstate>('slotstates', ref =>
      ref.where('listingId', '==', this.listingId).where('ymd', '>', this.ymdtoday).orderBy('ymd', 'asc'));
    this.slotstates = this.slotstateCollection.valueChanges({idField: 'id'});
    this.slotstates.subscribe((slotstates) => {
      const newMap: Map<string, string> = new Map();
      slotstates.forEach((slotstate: Slotstate) => {
        newMap.set(slotstate.id, slotstate.status);
      });
      this.slotstateMap = newMap;
    });
  }

  ngOnInit() {
  }

  hascartslots() {
    let state = 'empty';
    if (this.cartslotDocs.length > 0) {
      this.cartslotDocs.forEach((cartslot) => {
        if (cartslot.fullname === undefined || cartslot.fullname === '') {
          state = 'incomplete';
        }
      });
      if (state === 'empty') {
        state = 'complete';
      }
    }
    return state;
  }

  slotbuttoncolor(calendarday: Calendarday, slot: number) {
    let slotbuttoncolor = 'primary';
    const slotstate: {slotstate: string; mycart: string; } = this.slotstate(calendarday, slot);
    if (slotstate.mycart === 'cart') {
      slotbuttoncolor = 'accent';
    }
    if (slotstate.slotstate !== undefined && slotstate.mycart !== 'cart') {
      slotbuttoncolor = 'warn';
    }

    return slotbuttoncolor;
  }

  async selectslot(calendarday: Calendarday, slot: number) {
    if (!this.slotbuttondisabled(calendarday, slot)) {
      this.loadingMap.set(this.listingId + '_' + calendarday.ymd.toString() + '_' + slot.toString(), 'loading');
      console.log(this.listingId + '_' + calendarday.ymd.toString() + '_' + slot.toString());
      await this.bookingService.selectSlot(this.listingId, calendarday.ymd, slot);
      this.loadingMap.delete(this.listingId + '_' + calendarday.ymd.toString() + '_' + slot.toString());
    } else {
      alert('This slot is not available');
    }
  }

  slotbuttondisabled(calendarday, slot: number) {
    const slotstate: {slotstate: string; mycart: string; } = this.slotstate(calendarday, slot);
    if (slotstate.mycart === 'cart' || slotstate.slotstate === undefined) {
      return false;
    }
    return true;
  }

  slotbuttonloading(calendarday, slot: number) {
    if (this.loadingMap.get(this.listingId + '_' + calendarday.ymd.toString() + '_' + slot.toString()) !== undefined) {
      return true;
    }
    return false;
  }

  slotstate(calendarday: Calendarday, slot: number) {
    // console.log(this.listingId + '_' + calendarday.ymd.toString() + '_' + slot.toString(), this.slotstateMap, this.cartslotMap);
    return {
      slotstate: this.slotstateMap.get(this.listingId + '_' + calendarday.ymd.toString() + '_' + slot.toString()),
      mycart: this.cartslotMap.get(this.listingId + '_' + calendarday.ymd.toString() + '_' + slot.toString())
    };
  }

  dayselected(calendarday) {
    if (this.cartslotMap.get(this.listingId + '_' + calendarday.ymd.toString() + '_1') === 'cart'
      || this.cartslotMap.get(this.listingId + '_' + calendarday.ymd.toString() + '_2') === 'cart'
      || this.cartslotMap.get(this.listingId + '_' + calendarday.ymd.toString() + '_3') === 'cart'
      || this.cartslotMap.get(this.listingId + '_' + calendarday.ymd.toString() + '_4') === 'cart'
    ) {
      return true;
    }
    return false;
  }

  async confirmCart() {
    if (this.hascartslots() === 'incomplete') {
      alert('You need to provide sponsorship details for each Kiddush you are editing before you can check out.');
    } else {
      const cartid = await this.bookingService.getcartid(this.listingId);
      this.router.navigate(['confirmcart', cartid ]);
    }
  }

  async getBookingDetails(calendarday, slot) {
    const booking = await this.bookingService.getBooking(this.listingId, calendarday, slot);
    this.bookingsMap.set(this.listingId + '_' + calendarday.ymd.toString() + '_' + slot.toString(), booking);

  }

  async hideBookingDetails(calendarday, slot) {
    const booking = this.bookingsMap.get(this.listingId + '_' + calendarday.ymd.toString() + '_' + slot.toString());
    booking.hide = true;
    this.bookingsMap.set(this.listingId + '_' + calendarday.ymd.toString() + '_' + slot.toString(), booking);
  }
}

